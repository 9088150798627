/**************************************
    Default Styles
***************************************/

@import url('../css/bootstrap.min.css');
@import url('../css/slick.css');
@import url('../css/slicktheme.css');
@import url('../css/animation.css');



@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/common';
@import 'default/forms';



@import 'color/themebg';


@import 'header/header';
@import 'header/nav';
@import 'header/mobilemenu';
@import 'header/headertop';



@import 'elements/sctiontitle';
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/accordion';
@import 'elements/service';
@import 'elements/card';
@import 'elements/progressbar';
@import 'elements/counter';
@import 'elements/portfolio';
@import 'elements/social';
@import 'elements/team';
@import 'elements/timeline';
@import 'elements/testimonial';
@import 'elements/slickslide';
@import 'elements/tab';
@import 'elements/pricing';
@import 'elements/split';
@import 'elements/callto-action';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/brand';
@import 'elements/newsletter';
@import 'elements/advance-tab';
@import 'elements/advancepricing';
@import 'elements/about';
@import 'elements/swicher';
@import 'elements/404';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/sidebar';
@import 'blog/blog-details';



/**************************************
    Footer Styles
***************************************/
@import 'template/banner';
@import 'template/portfoliodetails';
@import 'template/splash';



/**************************************
    Footer Styles
***************************************/

@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/darkmode';

/*********** CUSTOM CSS SUKANT ***************/
hr {
    opacity: 1;
}

p.description.b1 {
    max-width: 720px;
}

.slider-style-1 .inner .subtitle {
    font-size: 32px;
    color: var(--color-white);
    letter-spacing: 5px;
}

.card-box.card-style-1 {
    height: 430px !important;
}

.text-center {
    text-align: -webkit-center !important;
}

p.projectpoints {
    margin-top: 10px;
    margin-bottom: 10px;
}

.timeline-style-two {
    padding: 50px;
}

.rn-timeline-single.no-gradient .rn-timeline .progress-line .line-inner {
    background-image: inherit;
    background: var(--color-lessdark);
}

img.usr-img {
    max-width: 270px;
    padding-bottom: 30px;
    padding-top: 50px;
}

.rn-address {
    min-height: 176px;
    padding: 30px;
}

.align-right-new {
    justify-content: flex-end;
}

.single-timeline .single-content .inner .content .date-of-timeline {
    margin-bottom: 10px;
}

.w-80 {
    width: 80% !important;
}

.service.gallery-style {
    padding: 10px;
    background: none;
    border-radius: 0px;
    box-shadow: none;
    margin-top: 0px;
}

.rn-address .icon {
    border-radius: 0;
    margin-bottom: 6px;
    margin-right: 10px;
}

.service-icon {
    width: 50px;
    padding-bottom: 20px;
}

.testimonial-style-two .content .description {
    font-size: 26px;
}

.p--60 {
    padding: 60px !important;
}

.rn-back-top {
    background-color: #393939;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-timeline {
        padding-left: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .copyright-style-one .ft-menu {
        justify-content: start;
    }
    .slick-space-gutter--15 .testimonial-style-two {
        padding-left: 20px;
        padding-right: 20px;
    }
    .testimonial-style-two .content .description {
        font-size: 22px;
    }

    #phone-number {
        display: none;
    }
}